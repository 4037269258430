<template>
  <table class="result-wrapper">
    <tbody>
      <tr class="border-bottom">
        <td>Maximale Querkraft</td>
        <td class="align-right">v<sub>Ed</sub> = V<sub>d</sub>/H</td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.maxLateralForce.toFixed(2).replace('.', ',') + ' kN/m' }}</td>
      </tr>
      <tr class="border-bottom">
        <td>Max. Schubkraft ohne Verdübelung</td>
        <td class="align-right">v<sub>Rd,ct</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.maxThrustWithoutDowelling.toFixed(2).replace('.', ',') + ' kN/m' }}</td>
      </tr>
      <tr>
        <td>Dübelbewehrung</td>
        <td class="align-right">erf A<sub>sd,5</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.dowelReinforcement.toFixed(2).replace('.', ',') + ' cm²' }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ResultTableFive",
  computed: {
    ...mapGetters(['results']),
    resultItem() {
      return this.results.paneDetection[4];
    }
  }
}
</script>

<style lang="scss">
</style>