<template>
  <div class="result-container">
    <img class="result-image spacing" :src="systemDirectionImage">

    <Tabs v-model="selectedTabIndex">
      <TabsItem title="Bemessung Zuggurt und Druckstrebe" :help-component="() => import('@/components/PaneDetection/Help/ResultTabOneHelp')">
        <ResultTableOne />
      </TabsItem>
      <TabsItem title="Bemessung Zugpfosten" :help-component="() => import('@/components/PaneDetection/Help/ResultTabTwoHelp')">
        <ResultTableTwo />
      </TabsItem>
      <TabsItem title="Bemessung Fugen" :help-component="() => import('@/components/PaneDetection/Help/ResultTabThree')">
        <ResultTableThree />
      </TabsItem>
      <TabsItem title="Anschluss an aussteifendes Bauteil" :help-component="() => import('@/components/PaneDetection/Help/ResultTabFourHelp')">
        <ResultTableFour />
      </TabsItem>
      <TabsItem title="Schubkraftübertragung" :help-component="() => import('@/components/PaneDetection/Help/ResultTabFiveHelp')">
        <ResultTableFive />
      </TabsItem>
    </Tabs>

    <div class="output-result-list">
      <button @click="pdf()" class="output-result-item">
        <img :src="require('@/assets/file_download.svg')">
        <span>Nachweis herunterladen</span>
      </button>
      <button @click="print()" class="output-result-item">
        <img :src="require('@/assets/print.svg')">
        <span>Nachweis drucken</span>
      </button>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs";
import TabsItem from "@/components/TabsItem";
import ResultTableOne from "@/components/PaneDetection/ResultTableOne";
import ResultTableTwo from "@/components/PaneDetection/ResultTableTwo";
import ResultTableThree from "@/components/PaneDetection/ResultTableThree";
import ResultTableFour from "@/components/PaneDetection/ResultTableFour";
import ResultTableFive from "@/components/PaneDetection/ResultTableFive";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Result",
  components: {ResultTableFive, ResultTableFour, ResultTableThree, ResultTableTwo, ResultTableOne, TabsItem, Tabs},
  data() {
    return {
      selectedTabIndex: 0
    }
  },
  computed: {
    ...mapGetters(['options']),
    systemDirectionImage() {
      return require('@/assets/system_direction_' + this.options.systemDirection + '_' + (this.selectedTabIndex + 1) +'.svg');
    }
  },
  methods: {
    ...mapActions(['pdfPaneDetectionResults', 'printPaneDetectionResults']),
    pdf() {
      this.pdfPaneDetectionResults();
    },
    print() {
      this.printPaneDetectionResults();
    }
  }
};
</script>

<style lang="scss">
.pane-detection-main {
  .result-container {
    .result-image {
      width: 100%;
      height: auto;
    }

    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      border: 1px solid #E3000B;
      border-radius: 50%;
      cursor: pointer;
      color: #E3000B;
      background-color: #FFFFFF;
      font-weight: bold;
      font-size: 0.762rem;

      &:hover {
        color: #FFFFFF;
        background-color: #E3000B;
      }

      &.selected {
        color: #FFFFFF;
        background-color: #E3000B;
      }
    }

    .spacing {
      margin-bottom: 48px;
    }

    .result-wrapper {
      display: grid;
      grid-template-columns: 5fr 1fr 1fr 1fr;
      width: 100%;
      margin-bottom: 48px;

      font-size: 0.762rem;
      color: #293136;

      tbody, tr {
        display: contents;
      }

      tr {
        height: auto;

        &.border-bottom {
          td {
            border-bottom: 1px solid #69777F;
          }
        }

        td {
          width: 100%;
          height: 100%;
          padding: 8px 0 8px 0;
        }
      }

      tbody {
        font-family: futura-pt-book, sans-serif;

        sub {
          vertical-align: sub;
          font-size: 0.5rem;
        }
      }
    }

    .output-result-list {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &:before {
        content: '';
        position: absolute;
        left: -32px;
        width: calc(100% + 64px);
        height: 1px;

        background-color: #CAD3D9;
      }

      .output-result-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: futura-pt-condensed, sans-serif;

        &:first-child {
          padding: 16px 32px 16px 0px;
        }

        &:last-child {
          padding: 16px 0px 16px 32px;

          border-right: none;
        }

        font-size: 0.762rem;
        color: #293136;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;

        border-right: 1px solid #CAD3D9;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }

        img {
          width: 24px;
          height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>