<template>
  <main class="pane-detection-main" id="main">
    <h6 v-if="isMobile" class="page-title">SCHEIBENNACHWEIS VMM-DECKE</h6>

    <div :class="{ 'grid two-columns': true, 'hide-result': isMobile || isMobile && !done }">
      <div class="grid-item">
        <InputManager result="paneDetection" @done="done = true" :items="inputItems"/>
        <Imprint v-if="!isMobile" />
      </div>
      <div class="grid-item">
        <div v-if="!isMobile || isMobile && done" class="result">
          <Result />
        </div>
        <Imprint v-if="isMobile" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import InputManager from "@/components/InputManager";
import Result from "@/components/PaneDetection/Result";
import Imprint from "@/components/Imprint";
export default {
  name: "PaneDetection",
  components: {Imprint, InputManager, Result},
  data() {
    return {
      done: false,
      inputItems: [
        { title: 'Systeme', component: () => import('@/components/PaneDetection/Systems'), helpComponent: () => import('@/components/PaneDetection/Help/SystemsHelp') },
        { title: 'Belastung', component: () => import('@/components/PaneDetection/Load') }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  }
};
</script>

<style lang="scss">
@import "src/scss/paneDetectionPrint";

.pane-detection-main {
  >.grid {
    grid-column-gap: 160px;
  }

  .page-title {
    margin: 15px 0 32px 0;

    font-size: 1.75rem;
    line-height: 2.2rem;
    color: #E3000B;
  }

  .hide-result {
    grid-template-columns: 1fr;
  }

  .input-manager {
    margin-bottom: 64px;
  }

  .result {
    width: 100%;
    padding: 32px 32px 0 32px;
    margin-bottom: 64px;

    background-color: #FFFFFF;
    border: 1px solid #CAD3D9;

    @media (max-width: $width-tablet) {
      width: calc(100% + 16px * 2);
      padding: 32px 16px 0 16px;
      margin-left: -16px;
      overflow: hidden;
    }
  }
}
</style>