<template>
  <div v-show="selected" class="tabs-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabsItem",
  props: {
    title: String,
    helpComponent: Function
  },
  data() {
    return {
      selected: false
    }
  }
}
</script>

<style lang="scss">

</style>