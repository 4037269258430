<template>
  <table class="result-wrapper">
    <tbody>
      <tr>
        <td>Fugenkraft</td>
        <td class="align-right">F<sub>Ed</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.jointForce[0].toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr class="border-bottom">
        <td></td>
        <td class="align-right">F<sub>Ed,min</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.jointForce[1].toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr>
        <td>Fugenbewehrung</td>
        <td class="align-right">erf A<sub>sd,3</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.jointReinforcement.toFixed(2).replace('.', ',') + ' cm²' }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ResultTableThree",
  computed: {
    ...mapGetters(['results']),
    resultItem() {
      return this.results.paneDetection[2];
    }
  }
}
</script>

<style lang="scss">
</style>