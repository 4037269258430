<template>
  <table class="result-wrapper">
    <tbody>
      <tr>
        <td>Anschlusskraft</td>
        <td class="align-right">F<sub>Ed</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.connectionForce[0].toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr class="border-bottom">
        <td></td>
        <td class="align-right">F<sub>Ed,min</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.connectionForce[1].toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr>
        <td>Ringankerbewehrung</td>
        <td class="align-right">erf A<sub>sd,4</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.ringAnchorReinforcement.toFixed(2).replace('.', ',') + ' cm²' }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ResultTableFour",
  computed: {
    ...mapGetters(['results']),
    resultItem() {
      return this.results.paneDetection[3];
    }
  }
}
</script>

<style lang="scss">
</style>