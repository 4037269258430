<template>
  <table class="result-wrapper">
    <tbody>
      <tr class="border-bottom">
        <td>Hebelarm Scheibe</td>
        <td class="align-right">z</td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.leverArmPane.toFixed(2).replace('.', ',') + ' m' }}</td>
      </tr>
      <tr>
        <td>Ringankerkraft</td>
        <td class="align-right">F<sub>Ed</sub> = M<sub>d</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.ringAnchorForce[0].toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr class="border-bottom">
        <td></td>
        <td class="align-right">F<sub>Ed,min</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.ringAnchorForce[1].toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr class="border-bottom">
        <td>Ringankerbewehrung</td>
        <td class="align-right">erf A<sub>sd,1</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.ringAnchorReinforcement.toFixed(2).replace('.', ',') + ' cm²' }}</td>
      </tr>
      <tr class="border-bottom">
        <td>Druckstrebe</td>
        <td class="align-right">cot = l/z</td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.strut.toFixed(2).replace('.', ',') }}</td>
      </tr>
      <tr class="border-bottom">
        <td>Nachweis Druckstrebe</td>
        <td class="align-right">V<sub>Rd,max</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.proofStrut.toFixed(2).replace('.', ',') + ' kN' }}</td>
      </tr>
      <tr>
        <td>Fugenbeton mind. C20/25</td>
        <td class="align-right">V<sub>d</sub>/V<sub>Rd</sub></td>
        <td class="align-center">=</td>
        <td class="align-right">{{ resultItem.jointConcrete.toFixed(2).replace('.', ',') }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ResultTableOne",
  computed: {
    ...mapGetters(['results']),
    resultItem() {
      return this.results.paneDetection[0];
    }
  }
}
</script>

<style lang="scss">
</style>