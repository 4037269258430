<template>
  <div class="tabs-container">
    <ul class="tabs-header">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          @click="selectTab(index)"
          :class="{ 'selected': selectedIndex === index }">
        <div :class="{ 'marker': true, 'selected': selectedIndex === index }">{{ index + 1 }}</div>
        <span v-show="selectedIndex === index || isMobile">{{ tab.title }}</span>

        <button v-if="selectedIndex === index && tab.helpComponent" @click="openHelpPopup(tab.helpComponent)">
          <img :src="require('@/assets/help.svg')" />
        </button>
      </li>
    </ul>

    <slot></slot>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Tabs",
  props: {
    value: Number
  },
  data() {
    return {
      selectedIndex: this.value,
      tabs: []
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    ...mapActions(['updatePopupHelpComponent']),
    openHelpPopup(helpComponent) {
      console.log(helpComponent);
      this.updatePopupHelpComponent(helpComponent);
    },
    selectTab(newIndex) {
      this.selectedIndex = newIndex;
      this.$emit('input', newIndex);

      this.tabs.forEach((tab, index) => {
        tab.selected = index === newIndex;
      });
    }
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(this.selectedIndex);
  }
}
</script>

<style lang="scss">
.tabs-container {
  .tabs-header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-bottom: 14px;
    margin-bottom: 32px;

    font-weight: bold;
    font-size: 0.762rem;
    list-style: none;
    border-bottom: 1px solid #CAD3D9;

    @media (max-width: $width-tablet) {
      flex-direction: column;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;
      color: #69777F;

      @media (max-width: $width-tablet) {
        padding-left: 10px;
        font-size: 1rem;
      }

      &.selected {
        position: relative;

        color: #E3000B;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -14px;
          width: 100%;
          height: 4px;
          background-color: #E3000B;
        }

        @media (max-width: $width-tablet) {
          &:after {
            width: 4px;
            height: 100%;
            top: 0;
            left: 0;
            bottom: initial;
          }
        }
      }

      .marker + span {
        margin-left: 8px;
      }

      button {
        margin-left: 8px;
        padding: 0;

        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}
</style>